const main = [
    {id: 'task-types', title: 'Аренда спецтехники, Доставка материалов, Грузоперевозки, Пассажирские перевозки'},
    {id: 'causes', title: 'Почему мы?'},
    {id: 'tasks', title: 'Новые заказы'},
    {id: 'how-to-start', title: 'Как начать работать?'},
    {id: 'registration', title: 'Сформируй заказ и выбери исполнителя'},
    {id: 'vehicles', title: 'Техника'},
    {id: 'about', title: 'О сервисе'},
    {id: 'partnership', title: 'Стань партнером Veresking и получай доход за каждого приглашенного пользователя'},
    {id: 'prize', title: 'Принимай участие в розыгрыше суперприза – Мини-погрузчика'},
    {title: 'Вопросы и ответы', id: 'faq'}
]


export const SEARCH_DATA: { [key: string]: { title: string, id: string }[] } = {
    executor: main,
    customer: main,
    tariffs: [
        {id: 'tariff-plans', title: 'Выбери свою роль'},
        {id: 'services', title: 'Дополнительные услуги'},
        {title: 'Вопросы и ответы', id: 'faq'}
    ],
    partner: [{
        title: 'Тот случай, когда рекомендовать сервис — выгодно',
        id: 'benefit'
    }, {
        title: 'Станьте партнёром',
        id: 'become-partner'
    }, {
        title: 'Привлекать новых клиентов и зарабатывать может каждый, присоединяйтесь',
        id: 'socials'
    }, {
        title: 'Начать зарабатывать — легко',
        id: 'steps'
    }, {
        title: 'Удобный личный кабинет со всеми инструментами для работы',
        id: 'personal-account'
    }, {
        title: 'Присоединяйтесь прямо сейчас',
        id: 'registration'
    }, {
        title: 'Нас выбирают за наши условия',
        id: 'conditions'
    }, {
        title: 'Вопросы и ответы',
        id: 'faq'
    }]
}