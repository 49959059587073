import s from './styles.module.scss'

interface IProps {
  handleClose: () => void
}

export const CookieAcceptPopup = ({ handleClose }: IProps) => {
  return <div className={s.wrapper}>
    <p className={s.text}>Мы используем файлы cookie для улучшения работы сайта.
      Продолжая использовать ресурс, вы автоматически соглашаетесь
      на использование данной технологии.</p>
    <button onClick={handleClose} className={s.close} />
  </div>
}