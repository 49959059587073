'use client'

import s from './style.module.scss'
import { useEffect, useState } from 'react'
import { RoleTypeExtended } from '@/interfaces/RoleType'

export const UpButton = ({ roleType }: { roleType: RoleTypeExtended }) => {
  const [isVisible, setIsVisible] = useState(false)
  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 700) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return isVisible ? <button className={`${s.button} ${s[roleType]}`} onClick={scrollToTop}>
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' stroke={`var(--primary-${roleType}-color)`}
         xmlns='http://www.w3.org/2000/svg'>
      <path d='M15 6.25V23.75' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.75 12.5012L15.0012 6.25L21.2525 12.5012' strokeWidth='1.5' strokeLinecap='round'
            strokeLinejoin='round' />
    </svg>

  </button> : null
}