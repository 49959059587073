'use client'
import s from "@/ui/FAQ/styles.module.scss";
import React, {useState} from "react";
import {IFaq} from "@/interfaces/IFaq/IFaq";
import {RoleTypeExtended} from "@/interfaces/RoleType";

export const FaqItem = ({question, answer, roleType}: IFaq & { roleType: RoleTypeExtended }) => {

    const [isOpened, setIsOpened] = useState(false)

    const handleClick = () => {
        setIsOpened(!isOpened)
    }

    return <>
        <dt className={`${s.header} ${isOpened ? s.active : ''}`}>
            {question}
            <button onClick={handleClick} className={`${s.button}`}/>
            <svg className={`${s.svg} ${isOpened ? s.active : ''}`} width='20' height='20'
                 viewBox='0 0 20 20' fill='none'
                 xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M15.832 9.19271H10.832V4.19271C10.832 3.97169 10.7442 3.75973 10.588 3.60345C10.4317 3.44717 10.2197 3.35938 9.9987 3.35938C9.77769 3.35938 9.56572 3.44717 9.40944 3.60345C9.25316 3.75973 9.16537 3.97169 9.16537 4.19271V9.19271H4.16536C3.94435 9.19271 3.73239 9.28051 3.57611 9.43679C3.41983 9.59307 3.33203 9.80503 3.33203 10.026C3.33203 10.2471 3.41983 10.459 3.57611 10.6153C3.73239 10.7716 3.94435 10.8594 4.16536 10.8594H9.16537V15.8594C9.16537 16.0804 9.25316 16.2924 9.40944 16.4486C9.56572 16.6049 9.77769 16.6927 9.9987 16.6927C10.2197 16.6927 10.4317 16.6049 10.588 16.4486C10.7442 16.2924 10.832 16.0804 10.832 15.8594V10.8594H15.832C16.053 10.8594 16.265 10.7716 16.4213 10.6153C16.5776 10.459 16.6654 10.2471 16.6654 10.026C16.6654 9.80503 16.5776 9.59307 16.4213 9.43679C16.265 9.28051 16.053 9.19271 15.832 9.19271Z'
                    fill={`var(--primary-${roleType}-color)`}/>
            </svg>

        </dt>
        <dd className={`${s.content} ${isOpened ? s.active : ''}`}>{answer}</dd>
    </>
}