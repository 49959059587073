'use client'

import s from './styles.module.scss'
import {usePathname, useRouter} from 'next/navigation'
import {ChangeEvent, useState} from "react"
import {SEARCH_DATA} from "@/searchData"
import {DEFAULT_ROLE_TYPE} from "@/CONSTANTS"
import {RegularText} from "@/ui/Text"

const ERRORS = {
    min: 'Минимальная длина 3 символа',
    empty: 'Ничего не найдено'
}

export const Search = ({closeMenu}: { closeMenu?: () => void }) => {
    const router = useRouter()
    const pathName = usePathname().slice(1) || DEFAULT_ROLE_TYPE

    const [value, setValue] = useState('')
    const [error, setError] = useState('')

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSearch()
        }
    }
    const handleSearch = () => {
        const searchValue = value.trim().toLowerCase()
        if (!searchValue) return

        if (searchValue.length < 3) {
            setError(ERRORS['min'])
            return
        }

        const res = SEARCH_DATA[pathName] ? SEARCH_DATA[pathName].find(item => item.title.toLowerCase().includes(searchValue))?.id : null

        if (!res) {
            setError(ERRORS['empty'])
            return
        }

        router.replace(`#${res}`)
        closeMenu?.()
    }

    const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
        if (error) setError('')

        setValue(e.target.value)
    }

    return <div style={{position: 'relative'}}>
        <div className={s.wrapper}>
            <input placeholder={'Поиск'} className={s.input} type='text' onChange={handleInput}
                   onKeyDown={handleKeyDown}/>

            <button className={s.button} onClick={handleSearch}/>
        </div>
        {!!error &&
            <RegularText style={{position: 'absolute', color: 'var(--text-color-inverted)'}}>{error}</RegularText>}
    </div>
}