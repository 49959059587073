'use client'
import s from './styles.module.scss'
import Image from 'next/image'
import logo from '@/images/logo.png'
import { Navigation } from '../Navigation'
import { useEffect, useState } from 'react'
import { Search } from '@/ui/Header/Search'
import { Login } from '@/ui/Header/Login'
import { CookieAcceptPopup } from '@/ui/CookieAcceptPopup'
import { PageType } from '@/interfaces/PageType'
import { navigationItems } from '@/CONSTANTS'
import { UpButton } from '@/ui/UpButton'
import Link from 'next/link'

interface IProps {
  roleType: 'customer' | 'executor' | 'partner'
  pageType: PageType
}

export const Header = ({ roleType, pageType }: IProps) => {

  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const toggleMenu = () => {
    setIsMenuOpened(prev => !prev)
  }

  const closeMenu = () => {
    setIsMenuOpened(false)
  }

  const [isPopupOpened, setIsPopupOpened] = useState(false)

  useEffect(() => {
    if (!localStorage.getItem('cap')) { //cookie accept popup
      setTimeout(() => {
        setIsPopupOpened(true)
      }, 2000)
    }
  }, [])

  const handleClosePopup = () => {
    setIsPopupOpened(false)
    localStorage.setItem('cap', 'true')
  }

  return <header className={s.wrapper}>
    <UpButton roleType={roleType} />
    {isPopupOpened && <CookieAcceptPopup handleClose={handleClosePopup} />}
    {isMenuOpened && <div className={`${s['mobile-menu-wrapper']} ${s.mobile}`}>
      <div className={s['mobile-menu-inner']}>
        <Search closeMenu={closeMenu}/>
        <Navigation navigationItems={navigationItems} pageType={pageType} />
        <Login roleType={roleType} />
      </div>
    </div>}

    <div className={'container'}>

      <div className={s.mobile}>
        <div className={s.inner}>
          <Link href={'/'}>
            <Image src={logo.src} width={160} height={55} alt={'Логотип Veresking'} />
          </Link>
          <div className={`${s.hamburger} ${isMenuOpened ? s.opened : s.closed}`}>
            <button onClick={toggleMenu} />
          </div>
        </div>
      </div>

      <div className={s.desktop}>
        <div className={s.inner}>
          <Link href={'/'}>
            <Image src={logo.src} width={200} height={68} alt={'Логотип Veresking'} />
          </Link>
          <div className={s['search-wrapper']}>
            <Search/>
          </div>
          <div className={s['login-wrapper']}>
            <Login roleType={roleType} />
          </div>
        </div>
        <Navigation navigationItems={navigationItems} pageType={pageType} />
      </div>
    </div>
  </header>
}